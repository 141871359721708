<template>
  <div class="w-10/12">
    <h1>{{ title }}:</h1>
    <p class="font-Hind font-medium text-base -md:ml-8 ml-4 text-left md:text-xl md:text-justify">{{ flavor }} </p>
  </div>
</template>

<script>
export default {
  name: 'FlavorText',
  props: {
      title: String,
      flavor: String,
  }
}
</script>
