import Vue from 'vue'
import App from './App.vue'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import './index.css'

Vue.config.productionTip = false

// https://docs.metamask.io/guide/ethereum-provider.html#events
let eth = window.ethereum;
if (eth) {
  eth.on('connect', (connectInfo) => {
    console.log(connectInfo);
  });
  eth.on('disconnect', (error) => {
    console.log(error);
  });
  eth.on('accountsChanged', (accounts) => {
    console.log(accounts);
  });
  eth.on('chainChanged', (chainId) => {
    console.log(chainId);
  });
  eth.on('message', (message) => {
    console.log(message);
  });
}

Vue.use(VueToast);

new Vue({
  render: h => h(App),
  data: {
    eth
  }
}).$mount('#app')
