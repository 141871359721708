<template>
  <div id="app" name="top" class="bg-gray-900 text-gray-200">
    <TopBar class="shadow-lg fixed w-full bg-gray-900 z-40" />
    <div
      :style="bgZoomOffset"
      class="
        bg-banner-square
        2xl:bg-banner
        bg-fixed
        h-screen
        flex flex-col
        justify-center
        items-center
        bg-top bg-no-repeat
      "
    >
      <MintPanel label="MINT" />
    </div>
    <div
      id="content"
      class="py-10 max-w-max space-y-10 flex flex-col items-center"
    >
      <div id="links" class="flex flex-row justify-center space-x-4">
        <button class="btn btn-blue text-4xl" @click="openDiscord">
          Discord
        </button>
        <button
          class="hidden md:block btn btn-blue text-4xl"
          @click="openTwitter"
        >
          Twitter
        </button>
        <button
          class="hidden md:block btn btn-blue text-4xl"
          @click="openOpenSea"
        >
          OpenSea
        </button>
        <button
          class="hidden md:block btn btn-blue text-4xl"
          @click="openContract"
        >
          Etherscan
        </button>
      </div>

      <h1 class="text-center md:text-justify">
        THE NEKOCORE ARE 9,999 UNIQUE GENERATIVE HIGH QUALITY MECHA
        CATS ILLUSTRATED BY JED HENRY, WITH OVER 300 POSSIBLE TRAITS
        EACH WITH THEIR OWN STATS AND READY FOR BATTLE.
      </h1>
      <div
        id="sample-thumbnails"
        class="grid grid-cols-1 md:grid-cols-3 md:w-11/12 max-w-max gap-8"
      >
        <ImageModal image="t1-1" />
        <ImageModal image="t2-1" />
        <ImageModal image="t1-duo-1" />
      </div>
      <FlavorText
        title="THE STORY"
        class="space-y-2"
        flavor="In the year 9999, cats are the dominant species on Earth and Ethereum
          is the only currency. The Cat King Tetsurō is throwing a tournament to
          celebrate the upcoming Decamillennium. Each cat clan is sending their
          best Mecha fighters to compete in head to head combat. The champions
          will return home as heroes with their purrrses filled with rare items
          and special rewards. Good luck fighters - the future is meow!"
      />
      <img alt="Banner" src="./assets/lowerbanner.jpg" class="w-screen" />
      <FlavorText
        title="THE TOURNAMENT"
        class="space-y-2"
        flavor="Shortly after 10% of the NekoCore have been minted the Decamillennium Tournament will begin. All minted NekoCore Mechs will participate automatically. Drivers can passively let their fighters battle without any intervention, but can also tweak their Mech's settings to prepare for each fight. Each tournament will reward the winners with exclusive Bronze, Silver and Gold NekoCore NFTs that can only be acquired by placing in the tournament."
      />
      <FlavorText
        title="TOURNAMENT INFO"
        class="space-y-2"
        flavor="Tournaments last 1 month and start with all minted NekoCore Mechs battling on teams of 5 and then eventually head to head combat.  Battles will compare stats and use dice rolls to determine a winner. Full tournament mechanics will be announced soon. Each tournament will feature unique terrain and circumstances benefiting certain stats over others. Players can use an interactive tournament bracket where they can see results and upcoming matches."
      />
      <div
        id="sample-thumbnails"
        class="grid grid-cols-1 md:grid-cols-3 md:w-11/12 max-w-max gap-8"
      >
        <ImageModal image="t3-bronze" />
        <ImageModal image="t3-silver" />
        <ImageModal image="t3-gold" />
      </div>
      <img class="hidden md:block object-cover max-w-full max-h-screen" src="./assets/roadmap.jpg" />
      <img class="md:hidden block object-cover max-w-full max-w-full" src="./assets/roadmap-mobile.jpg" />
      <div id="team" class="flex flex-col w-10/12 md:w-11/12 max-w-max m-auto">
        <h1>THE TEAM:</h1>
        <div
          id="team-grid"
          class="flex flex-col md:grid md:grid-cols-3 gap-4 items-center"
        >
          <TeamMember
            name="THE.JED.HENRY"
            image="cat-03.png"
            role="Visionary"
            contact="TheJedHenry"
            description="Illustrator"
          />
          <TeamMember
            name="CAT.KING.TETSURO"
            image="cat-02.png"
            role="Creator"
            contact="GWarshNFT"
            description="Fearless Leader, Community Relations"
          />
          <TeamMember
            name="SELKIRK.REX"
            image="cat-04.png"
            role="Seeker"
            description="Engineering"
          />
          <TeamMember
            name="AKIRA.KIYOSHI"
            image="cat-12.png"
            role="Fat Cat"
            description="Creative Director, Game Design"
          />
          <TeamMember
            name="CRESCENS.KASIM"
            image="cat-11.png"
            role="Explorer"
            description="Engineering, Game Design"
          />
        </div>
      </div>
      <div
        id="footer-taglines"
        class="
          flex flex-col
          items-center
          text-center
          w-full
          space-y-2
          md:space-y-0
        "
      >
        <h1 class="text-2xl md:text-3xl">
          THE GREAT TOURNAMENT WILL SOON BEGIN...
        </h1>
        <h1 class="text-2xl md:text-3xl">ARE YOU FELINE LUCKY?</h1>
      </div>
      <div
        id="footer-logo"
        class="flex flex-col items-center space-y-16 w-full"
      >
        <a href="#top" class="w-1/2 md:w-1/4 max-w-max">
          <img src="./assets/logo.png" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// import Socials from "./components/Socials.vue";
import TeamMember from "./components/TeamMember.vue";
import TopBar from "./components/TopBar.vue";
import FlavorText from "./components/FlavorText.vue";
import ImageModal from "./components/ImageModal.vue";
import MintPanel from "./components/MintPanel.vue";

export default {
  name: "App",
  components: {
    // Socials,
    TopBar,
    TeamMember,
    FlavorText,
    ImageModal,
    MintPanel,
  },
  data: function () {
    return {
      scroll: 0,
      dimensions: {
        width: window.innerWidth,
        height: window.screen.availHeight,
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    bgZoomOffset: function () {
      let { width, height } = this.dimensions;
      let [top, bot] = height > width ? [2.6, 2.0] : [1.2, 1.0];
      let t = Math.min(1.0, this.scroll / (height * 0.5));
      let bg = (1 - t) * top + bot * t;
      return `background-size: ${bg * 100}%; padding-top: ${t * 500}px`;
    },
  },
  methods: {
    onScroll: function (e) {
      this.scroll = e.target.documentElement.scrollTop;
    },
    onResize: function () {
      this.dimensions = {
        width: window.innerWidth,
        height: window.screen.availHeight,
      };
    },
    openDiscord: function () {
      window.open("https://discord.gg/nekocore");
    },
    openTwitter: function () {
      window.open("https://twitter.com/nekocorenft");
    },
    openOpenSea: function () {
      window.open("https://opensea.io/collection/nekocore");
    },
    openContract: function () {
      window.open("https://etherscan.io/address/0xc1328cf1cf8db8a5fc407cd56759007c7d20e398#code");
    },
  },
};
</script>
