<template>
  <div>
    <div v-if="mobileSize">
      <img
        alt="Sample mech"
        v-bind:src="require(`@/assets/mechs/${image}.png`)"
      />
    </div>
    <div v-if="!mobileSize">
      <div
        class="
          fixed
          w-full
          h-full
          top-0
          left-0
          flex
          items-center
          justify-center
          p-16
        "
        v-if="show"
      >
        <div
          class="fixed w-full h-full bg-black opacity-70"
          @click="showModal(false)"
        />
        <img
          v-bind:src="require(`@/assets/mechs/lg/${image}.jpg`)"
          class="z-10 max-w-max max-h-full mx-auto"
        />
      </div>
      <img
        @click="showModal(true)"
        alt="Sample mech"
        v-bind:src="require(`@/assets/mechs/${image}.png`)"
        class="flex-1 self-center cursor-pointer"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageModal",
  props: {
    image: String,
  },
  data: function () {
    return {
      show: false,
      mobileSize: window.innerWidth < 768,
    };
  },
  methods: {
    showModal: function (s) {
      this.show = s;
    },
    onResize() {
      this.mobileSize = window.innerWidth < 768;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
