<template>
  <div id="panel" v-if="isPresale" class="space-y-24">
    <div v-if="isPublic" class="flex flex-col space-y-2 text-white min-w-max">
      <div
        id="mintinfo"
        class="text-white text-xs font-Countdown flex flex-col border border-purple-500"
      >
        <div class="bg-purple-900 p-2">
          {{ `NEKOS: ${this.currentMintPrice} Eth` }}
        </div>
      </div>
      <button
        id="btn-mint"
        :class="[connectionStyle, mintQuantityShakiness]"
        class="
          btn
          text-4xl
          sm:text-6xl
          md:text-7xl
          lg:text-8xl
          xl:text-9xl
          btn-blue
        "
        @click="publicMintOrConnect"
      >
        {{ mintButtonText }}
      </button>
      <div
        v-if="isConnected"
        class="
          flex flex-row
          space-x-2
          text-white text-2xl
          sm:text-4xl
          md:text-5xl
          lg:text-6xl
          xl:text-7xl
        "
      >
        <button
          id="btn-inc"
          :class="connectionStyle"
          class="btn"
          @click="inc()"
        >
          +
        </button>
        <button id="btn-1" :class="connectionStyle" class="btn" @click="inc(1)">
          1
        </button>
        <button id="btn-2" :class="connectionStyle" class="btn" @click="inc(2)">
          2
        </button>
        <button
          id="btn-10"
          :class="connectionStyle"
          class="btn"
          @click="inc(10)"
        >
          10
        </button>
        <button
          id="btn-max"
          :class="connectionStyle"
          class="btn flex-grow"
          @click="inc(20)"
        >
          MAX
        </button>
      </div>
    </div>
    <div v-if="isConnected" :class="[pilotPauseOrLive]" class="relative">
      <div
        id="mintinfo"
        class="text-white text-xs font-Countdown flex flex-col border border-purple-500 items-end bg-purple-900 p-2 my-2"
      >
          PILOTS: Free for NekoCore holders!
      </div>
      <button
        class="
          bg-indigo-300
          p-4
          text-white text-3xl
          sm:text-4xl
          md:text-5xl
          lg:text-6xl
          xl:text-7xl
          btn
          border-dashed border-8 border-indigo-100
          hover:border-green-200 hover:text-green-200 hover:bg-green-600
        "
        @click="claimPilots"
      >
        CLAIM NEKOPILOTS!
      </button>
      <img
        :class="[pilotPauseOrLive, pilotShakeOrNo]"
        class="
          absolute
          -left-10
          sm:-left-16
          md:-left-12
          lg:-left-20
          -top-12
          w-1/4
          md:w-1/5
        "
        v-bind:src="require(`@/assets/cats/tyvm/tyvm-tetsuro.png`)"
      />
    </div>
  </div>
  <div
    id="countdown"
    v-else
    class="
      text-white text-4xl
      sm:text-6xl
      md:text-7xl
      lg:text-8xl
      xl:text-9xl
      font-Countdown
      flex flex-col
      gap-4
    "
  >
    <div class="bg-purple-900 p-4">MAINTENANCE...</div>
    <div class="bg-purple-900 p-4">
      PLEASE HOLD<!-- {{ this.countdownToPresale }} -->
    </div>
  </div>
</template>

<script>
import TruffleContract from "@truffle/contract";
import { toChecksumAddress } from "ethereum-checksum-address";

const TWENTY_FOUR_HOURS_MS = 86400000;

export default {
  name: "MintPanel",
  props: {
    label: String,
  },
  data: function () {
    return {
      eth: this.$root.$data.eth,
      amount: 1,
      contracts: {},
      contractAddresses: {},
      releaseDateMs: NaN,
      currentTime: Date.now(),
      pilotMintPaused: true,
      mainMintPaused: false,
      currentMintPrice: 0.045,
    };
  },
  created: async function () {
    let [{ live, test, release_date }, abi_nkc, abi_nkcr, abi_nkcpp] =
      await Promise.all([
        fetch("https://contracts.nekocore.io").then((r) => r.json()),
        fetch("https://contracts.nekocore.io/abi/nkc").then((r) => r.json()),
        fetch("https://contracts.nekocore.io/abi/nkcr").then((r) => r.json()),
        fetch("https://contracts.nekocore.io/abi/nkcpp").then((r) => r.json()),
      ]);
    this.contracts = {
      nkc: TruffleContract(abi_nkc),
      nkcr: TruffleContract(abi_nkcr),
      nkcpp: TruffleContract(abi_nkcpp),
    };
    this.contractAddresses = {
      live,
      test,
    };
    // countdown. we include a 10s buffer to allow time for CloudFlare's KV data to propagate
    const msBufferTime = 10000;
    this.releaseDateMs = parseInt(release_date, 10) + msBufferTime;
    window.setInterval(() => {
      this.currentTime = Date.now();
    }, 10);

    // set up some pricing and pause information
    if (this.eth) {
      this.contracts.nkcpp.setProvider(this.eth);
      this.contracts.nkcr.setProvider(this.eth);
      let address = this.eth.chainId === "0x1" ? live : test;
      let [nkcpp_instance, nkcr_instance] = await Promise.all([
        this.contracts.nkcpp.at(address.nkcpp),
        this.contracts.nkcr.at(address.nkcr),
      ]).catch();
      let [pilotsMintable, nekoMintable, currentPrice] = await Promise.all([
        nkcpp_instance.MINTABLE(),
        nkcr_instance.MINTABLE(),
        nkcr_instance.PRICE(),
      ]).catch();
      this.pilotMintPaused = !pilotsMintable;
      this.mainMintPaused = !nekoMintable;
      // to get actual eth value you need to divide by 1e18 but 
      // we can't do that with plain js and bnjs doesn't support fractional values
      let reduced_to_numerical_space_we_can_deal_with = currentPrice.divn(1e7).divn(1e7);
      this.currentMintPrice = reduced_to_numerical_space_we_can_deal_with.toNumber() / 1e4;
    }
  },
  computed: {
    connectionStyle: function () {
      if (this.isConnected) {
        return this.eth.chainId === "0x1" ? "btn-live" : "btn-testnet";
      } else {
        return "btn-blue";
      }
    },
    pilotPauseOrLive: function () {
      return this.pilotMintPaused ? "filter grayscale" : "";
    },
    pilotShakeOrNo: function () {
      return !this.pilotMintPaused ? "animate-shake-low" : "";
    },
    mintQuantityShakiness: function () {
      if (this.amount === 20) {
        return "animate-shake-intense";
      } else if (this.amount >= 10) {
        return "animate-shake-high";
      } else if (this.amount >= 2) {
        return "animate-shake-mid";
      } else {
        return "animate-shake-low";
      }
    },
    isPresale: function () {
      return (
        !isNaN(this.releaseDateMs) && this.releaseDateMs - this.currentTime < 0
      );
    },
    isPublic: function () {
      return (
        !isNaN(this.releaseDateMs) &&
        this.releaseDateMs + TWENTY_FOUR_HOURS_MS - this.currentTime < 0
      );
    },
    isConnected: function () {
      return this.eth && this.eth.selectedAddress;
    },
    mintButtonText: function () {
      if (this.isConnected) {
        return `${this.label} ${this.amount}`;
      } else {
        return "Connect";
      }
    },
  },
  methods: {
    inc: function (n) {
      if (n) {
        this.amount = n;
      } else {
        this.amount += 1;
      }
      this.amount = Math.min(this.amount, 20);
    },
    publicMintOrConnect: async function () {
      if (this.eth) {
        if (this.eth.selectedAddress) {
          this.mint();
        } else {
          this.eth
            .request({
              method: "eth_requestAccounts",
            })
            .catch((err) => console.log(err));
        }
      } else {
        window.open("https://metamask.io/");
      }
    },
    mint: async function () {
      this.contracts.nkcr.setProvider(this.eth);
      let { live, test } = this.contractAddresses;
      let address = this.eth.chainId === "0x1" ? live : test;
      let click_url =
        this.eth.chainId === "0x1" ? "etherscan.io" : "rinkeby.etherscan.io";
      let instance = await this.contracts.nkcr.at(address.nkcr);

      let pendingToast = this.$toast.open({
        type: "info",
        position: "bottom",
        duration: 0, // don't time out
        dismissible: false,
        message: `Recruiting ${this.amount} NEKOCORE...`,
      });
      let success = (e) => {
        pendingToast.dismiss();
        this.$toast.open({
          type: "success",
          position: "bottom",
          duration: 0,
          message: `Successfully recruited ${this.amount} NEKOCORE! Click here to view transaction!`,
          onClick: () => {
            window.open(`https://${click_url}/tx/${e.tx}`);
          },
        });
      };
      let failure = () => {
        pendingToast.dismiss();
        this.$toast.open({
          type: "error",
          position: "bottom",
          message: `Failed to mint!`,
        });
      };
      // ^^^ common bits ----------------------------------------------------
      let [cost, isMintable] = await Promise.all([
        instance.PRICE(),
        instance.MINTABLE(),
      ]);

      // client side checks because you can't rely on metamask to bubble up errors properly
      // ------------------------------------------------------------------------------------
      if (!isMintable) {
        pendingToast.dismiss();
        this.$toast.open({
          type: "error",
          position: "bottom",
          message: `Contract is not currently mintable!`,
        });
        return;
      }
      // ------------------------------------------------------------------------------------
      let estimate;
      try {
        estimate = await instance.mint.estimateGas(this.amount, {
          from: this.eth.selectedAddress,
          value: cost * this.amount,
        });
      } catch (e) {
        pendingToast.dismiss();
        let usefulPart = e.message.split("\n")[0];
        if (usefulPart.startsWith("err: insufficient funds for gas")) {
          usefulPart = "insufficient funds in wallet";
        }
        this.$toast.open({
          type: "error",
          position: "bottom",
          message: usefulPart,
        });
        return;
      }
      instance
        .mint(this.amount, {
          from: this.eth.selectedAddress,
          value: cost * this.amount,
          gas: estimate,
        })
        .then(success)
        .catch(failure);
    },
    claimPilots: async function () {
      this.contracts.nkcpp.setProvider(this.eth);
      let { live, test } = this.contractAddresses;
      let address = this.eth.chainId === "0x1" ? live : test;
      let click_url =
        this.eth.chainId === "0x1" ? "etherscan.io" : "rinkeby.etherscan.io";
      let instance = await this.contracts.nkcpp.at(address.nkcpp);
      let pendingToast = this.$toast.open({
        type: "info",
        position: "bottom",
        duration: 0, // don't time out
        dismissible: false,
        message: `Checking for available claims...`,
      });
      let success = (e) => {
        pendingToast.dismiss();
        this.$toast.open({
          type: "success",
          position: "bottom",
          duration: 0,
          message: `Successfully claimed ${claimIDs.length} NEKOPILOTS! Click here to view transaction!`,
          onClick: () => {
            window.open(`https://${click_url}/tx/${e.tx}`);
          },
        });
      };
      let failure = () => {
        pendingToast.dismiss();
        this.$toast.open({
          type: "error",
          position: "bottom",
          message: `Failed to claim!`,
        });
      };
      // ^^^ common bits ----------------------------------------------------

      let checksum = toChecksumAddress(this.eth.selectedAddress);
      let [isMintable, claimIDs] = await Promise.all([
        instance.MINTABLE(),
        instance.claimsAvailable(checksum),
      ]).catch(failure);
      claimIDs = claimIDs.filter((e) => e.gtn(0));

      // client side checks because you can't rely on metamask to bubble up errors properly
      // ------------------------------------------------------------------------------------
      pendingToast.dismiss();
      if (!isMintable) {
        this.$toast.open({
          type: "error",
          position: "bottom",
          message: `Contract is currently paused!`,
        });
        return;
      }

      if (claimIDs.length > 0) {
        // replace the toast with info about the claim
        pendingToast = this.$toast.open({
          type: "info",
          position: "bottom",
          duration: 0, // don't time out
          dismissible: false,
          message: `Looks like you have ${claimIDs.length} eligible token(s)!`,
        });
      } else {
        this.$toast.open({
          type: "error",
          position: "bottom",
          message: `No eligible tokens for claim!`,
        });
        return;
      }
      // ------------------------------------------------------------------------------------

      let estimate;
      try {
        estimate = await instance.claimAll.estimateGas(claimIDs, {
          from: this.eth.selectedAddress,
        });
      } catch (e) {
        pendingToast.dismiss();
        let usefulPart = e.message.split("\n")[0];
        if (usefulPart.startsWith("err: insufficient funds for gas")) {
          usefulPart = "insufficient funds in wallet";
        }
        this.$toast.open({
          type: "error",
          position: "bottom",
          message: usefulPart,
        });
        return;
      }
      instance
        .claimAll(claimIDs, {
          from: this.eth.selectedAddress,
          gas: estimate,
        })
        .then(success, failure);
    },
  },
};
</script>
