<template>
  <div v-bind:id="name" class="flex flex-col">
    <img v-bind:src="require(`@/assets/cats/${image}`)" class="" />
    <h2 class="mt-2">{{ name }}</h2>
    <p>/ {{ role }} <span v-if="showContact">(<a :href="twitter" class="hover:underline text-purple-400">@{{contact}}</a>)</span></p>
    <p>/ {{ description }}</p>
  </div>
</template>

<script>
export default {
  name: "TeamMember",
  props: {
    name: String,
    description: String,
    role: String,
    image: String,
    contact: {
      type: String,
      default: null
    },
  },
  computed: {
    showContact: function () {
      return this.contact !== null;
    },
    twitter: function () {
      return `https://twitter.com/${this.contact}`;
    }
  }
};
</script>
